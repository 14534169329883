import Axios from "axios";

const API_URL = (() => {
  if (!process.env.GATSBY_API_URL) {
    throw new Error("GATSBY_API_URL not set");
  }
  return process.env.GATSBY_API_URL;
})();

export const ApiService = Axios.create({
  baseURL: API_URL,
});
